.research-heading {
  font-family: Raleway;
  font-size: 30px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 10px;
}
.guidelines {
  color: "white";
}
.disabled {
  pointer-events: none;
}

.dashed {
  border: 1px dashed #039938;
  background: #f1fcf1;
}
.upload {
  color: #343a40;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.remove {
  border-radius: 4px;
  background: #343a40;
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}
.textstyle {
  color: #000;
  font-family: Raleway;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.small-image {
  width: 20px;
  height: 20px;
}
.imageright {
  text-align: right;
}
.imageleft {
  text-align: left;
  margin-left: 10px;
  padding-right: 10px;
}
