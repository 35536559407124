.cs-main-container {
  margin: 20px;
  border-radius: 12px;
}
.search-bar {
  padding: 10px;
  border-radius: 22px;
}

.whatsapp-sidebar {
  border-right: black 2px solid !important;
}
.search-icon {
  padding: 12px;
  background-color: #039938;
  color: white;
  border-radius: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.whatsapp-header,
.cs-conversation-header__content,
.cs-conversation-header__user-name,
.cs-conversation-header__info {
  background-color: #039938 !important;
  color: WHITE !important;
}

.cs-main-container > .cs-sidebar.cs-sidebar--left {
  max-width: 22rem !important;
}

#intialChatScreen {
  background-color: #039938;
  padding: 220px;
  text-align: center;
  color: white;
  margin: 0 -1.2rem;
}

.chatMessages {
  background: #fff;
  border-radius: 5px;
  border-top-left-radius: 0;
  box-shadow: 1px 1px 1px 1px #0003;
  margin: 13px 5px 3px 20px;
  display: inline-block !important;
  padding: 6px 9px 8px;
}

.cs-conversation__content {
  width: 360px;
}
