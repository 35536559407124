.logo img {
  height: 4vmax;
  margin-bottom: 10px;
}

.logo {
  margin-top: 1.2rem;
  margin-bottom: 1.5rem;
  margin-left: 8.5rem;
}

.logo h6 {
  margin-left: 2.6rem;
  margin-top: 40px;
  font-size: 35px;
  color: black;
  font-weight: 700;
}

.regHead {
  height: 38rem;
  background-color: #039938;
  height: 50rem;
}

.regHead img {
  margin-top: 60px;
  height: 20vmax;
}

.regHead .content {
  font-size: 18px;
  color: white;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
}

.regHead p,
.regForm p {
  padding: 0px 70px;
}

.regHead h1 {
  font-size: 40px;
  line-height: 1.2;
  padding: 40px 100px 0 100px;
  font-weight: 700;
}

.googleImg {
  font-size: 20px;
  margin-bottom: 2px !important;
}

.googleBtn:hover {
  background-color: #039938;
  color: white !important;
}

.registrationContent {
  margin: 0px 100px 0 100px;
  font-weight: 500;
}

.registrationContent h2 {
  font-size: 35px;
  font-weight: 600;
}

.registrationContent p {
  font-size: 12px;
}

#regForm form {
  margin: 0 22%;
}

#regForm hr {
  margin: 0 35%;
}

.rec-dot {
  background-color: white !important;
  box-shadow: 0 0 1px 3px rgb(255, 255, 255) !important;
  margin: 0 14px !important;
}

.rec-dot_active {
  background-color: black !important;
  box-shadow: 0 0 1px 3px rgb(0, 0, 0) !important;
}

.cardTitle {
  margin: 20px 60px;
}

.cardContent {
  margin: 0 60px 60px 60px;
}
