#dashboard h2 {
  font-size: 64px !important;
  font-weight: 700;
}

#dashboard h3 {
  font-size: 2.3rem;
  font-weight: 600;
}
.mediaTitle {
  text-align: start;
  margin-left: 20px;
  margin-bottom: 20px;
  font-weight: 700;
}
.custom-card {
  border-radius: 12px; /* Rounded corners */
  border: 1px solid #e0e0e0; /* Light border */
  transition: transform 0.2s, box-shadow 0.2s;
  padding: 0;
}

.custom-card:hover {
  transform: translateY(-5px); /* Slight lift effect */
}

#dashboard .card-title {
  font-weight: 600; /* Bolder title */
  background-color: #218838;
}

.custom-link {
  font-weight: 500; /* Slightly bold links */
  text-decoration: none; /* No underline */
}

.custom-link:hover {
  color: #218838; /* Darker green on hover */
}

.card-links {
  display: flex;
  gap: 10px; /* Space between links */
}
