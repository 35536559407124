/* css for template category selection page */

.titleHead {
  margin-top: 30px !important;
}

.templateType h2 {
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 55px;
}

.templateType h2 + p {
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.15px;
  border-left: #153480 solid 5px;
  border-radius: 2px;
  padding: 0 0 0 10px;
}

.templateType .chooseTemplateSub {
  margin-top: 1.5rem;
  margin-bottom: 1.3rem;
  margin-left: 3.5rem;
  font-size: 2rem;
  font-weight: 800;
}

/* below code selects all the elements in the template card  */

.chooseTemplateSub + .row {
  margin-left: 4rem;
}

.ourcard {
  width: 22rem !important;
  margin-bottom: 3rem;
}

.arrow {
  margin-left: -5rem;
  margin-right: 1rem;
  margin-top: -3rem;
  width: 10.56256rem;
}

.ytThumbnail {
  width: 22vmax;
  margin-left: 1.7rem;
}

.title {
  font-size: 1.25rem !important;
  border-top-left-radius: 0.9375rem;
  border-top-right-radius: 0.9375rem;
  background: #039938;
  color: white;
  font-weight: 600;
  padding: 0.5rem 0;
}

.templateCard {
  padding: 0 0 1rem 0;
  width: 19rem !important;
  background-color: white;
  border-radius: 0.9375rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  z-index: 0;
}

.templateCard .cardImg {
  margin-top: 0.8rem;
  width: 3.4375rem;
  height: 3.125rem;
}

.templateCard p {
  padding: 0 1rem;
  font-size: 0.95rem;
  color: #000;
  font-weight: 500;
}

.buttonType {
  opacity: 1 !important;
  z-index: 1;
  border-radius: 0.625rem;
}
.buttonType:hover {
  background: #153480 !important;
}

/* css for template category selection page completed */

/* css for the form */

.formHeading {
  font-size: 2rem;
  font-weight: 500;
}

.spacing {
  margin-bottom: 29px !important;
}

.form-select {
  width: 12rem;
  border-radius: 0.9375rem !important;
  border: 1px solid #b5b5b5 !important;
  color: black !important;
}

.formBtns {
  background: #039938 !important;
  color: white;
  font-size: 12px !important;
}

.addVariable {
  height: 2.22988rem !important;
  font-size: 0.9rem;
  background: #039938 !important;
}

.addVariable:hover,
.formBtns:hover {
  color: white;
  background-color: #153480 !important;
}

.addVariable > .me-2 {
  font-size: 1.3rem;
}

.emojiPicker {
  position: relative;
  margin-left: 11.6rem !important;
}

.disabledBtn {
  border: solid 2px white;
}
.websiteButton {
  border-radius: 0.25rem;
  background: #f5f5f5;
  padding: 1rem 0;
}

.websiteButton h6 {
  font-size: 1.7rem;
  margin-top: 0.7rem;
  background-color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.6rem;
}

.websiteButton .myLabel {
  color: #8c8c8c;
  font-size: 0.9rem;
}

.urlInput {
  margin-left: 2.5rem;
  width: 28rem;
}

.callToActionBtn {
  background-color: #039938;
  color: white;
  margin-top: 1rem;
  margin-left: 0.5rem;
}

.removeButton {
  margin-left: 3rem !important;
  background-color: #212529;
  color: white;
}
.removeButton:hover,
.callToActionBtn:hover {
  background-color: #212529 !important;
  color: white !important;
}

#submitBtn {
  background-color: #039938;
}

.userErrors {
  color: red;
}

/* ending css for the form */

/* created template look css */
/* created template look css */

#templateForm {
  width: 43rem !important;
}

.preview {
  background: #005c50 !important;
  color: white;
  height: 87px;
  border-radius: 1.25rem 1.25rem 0rem 0rem;
}

.previewLogo {
  background-color: #fff;
  width: 3.375rem;
  height: 3.375rem;
  flex-shrink: 0;
  border-radius: 50%;
}

.previewLogo + h5 {
  font-size: 2rem;
  font-weight: 600;
}

.categories {
  margin-left: 160px;
}

#createdTemplateLook {
  margin-top: 0.9rem;
  margin-right: 1.2rem;
  padding: 1rem 0.7rem;
  background-color: white;
  border-radius: 1.25rem 1.25rem 0rem 0rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.myCard {
  background: url("./assets/previewBackground.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  height: 40vmax;
  border-radius: 0;
}

.templateLook {
  background-color: white;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  min-height: 20vmax;
  padding: 0px 1rem 0px 1rem;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
}

.previewHeader {
  font-size: 1.5625rem;
  margin-left: 20px;
  font-weight: 600;
  padding-top: 30px;
}

.bodyTextPreview {
  white-space: pre-wrap;
}

#body {
  margin-top: 4px;
}

#createdTemplateLook h6 {
  font-size: 1.5rem !important;
}

#createdTemplateLook p {
  font-size: 0.8rem !important;
}

#createdTemplateLook .footerText {
  color: rgba(0, 0, 0, 0.45);
  font-size: 18px;
}

.actionButtons p {
  color: #15a8ff !important;
  font-size: 0.9375rem;
}

.bodyVariable {
  color: #212529;
}

#variableSamples .justify-content-start {
  margin: 0 0 0 3rem !important;
}
.removeBodyVariable {
  background-color: #212529 !important;
  font-size: 16px !important;
  margin-bottom: 4px;
}

#variableSamples {
  border-radius: 20px;
  background-color: #f5f5f5 !important;
  padding: 10px 10px 20px 20px;
}

#variableSamples .form-control {
  width: 19rem !important;
}

#formNotification {
  padding: 0 !important;
}

#formNotification p {
  padding: 0 340px;
  font-size: 18px;
  line-height: 1.7;
}

#formNotification .myImg {
  height: 18vmax;
}

#formNotification .decoration1 {
  text-align: end !important;
}

#formNotification .decoration4 {
  text-align: end;
}

#formNotification h3 {
  font-weight: 600;
}

/* css for the text generator page */

.closeButton {
  font-size: 30px;
  background-color: #039938;
  color: white;
  padding: 0 !important;
  margin: 0 !important;
}

.userInputAi > .form-control {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px 0px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
    rgba(0, 0, 0, 0.2) 0px -3px 0px 0px inset;
}

.bottomButton {
  margin-top: 4rem !important;
}

#aiGenRegion {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px 0px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
    rgba(0, 0, 0, 0.2) 0px -3px 0px 0px inset;
}

.modal-content {
  padding: 2rem !important;
}

.modal-header {
  border-bottom: none !important;
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.innerModal {
  padding: 20px 45px !important;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
