.loginBlock {
  background-image: url("../images/loginBackground.png");
  background-size: cover;
  height: 26rem;
  z-index: 0;
  border-radius: 1.25rem;
}

.mainLogo {
  margin-top: 1.5rem;
  margin-right: 1rem;
  width: 25vmax;
}

.wbsServices {
  background: #039938;
  height: 50rem;
  text-align: center !important;
}

.welcomeText h2 {
  font-size: 2.125rem;
  margin-top: 2rem;
  color: white;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.welcomeText + p {
  margin: 0 9rem 1rem 9rem;
  font-size: 18px;
  font-style: normal;
  color: white;
  font-weight: 400;
  line-height: 1.5;
}

.noAccount {
  font-size: 20px;
  padding-left: 80px;
}

.content h1 {
  margin-top: -80px;
  font-size: 40px;
  font-weight: 700;
}

h2 {
  font-size: 2.1875rem;
  line-height: 94.1 px;
  font-weight: 600;
}

.logo img {
  width: 3.6vmax;
  margin-top: 30px;
}

.wbshead {
  font-weight: 500;
  font-size: 30px;
  color: #ffffff;
  padding-top: 30px;
}

.mainText {
  margin-left: 2.9rem;
  margin-right: 160px;
  font-size: 6rem;
  margin-top: 60px;
}

.loginCard {
  z-index: 3;
  background: #fff;
  margin: 0 auto;
  border-radius: 6%;
  border: 1px grey solid;
  width: 34rem !important;
}
.loginForm {
  padding: 0 4rem;
}
.loginForm label {
  display: block;
  text-align: start;
}

.loginForm .formInput {
  padding: 1rem;
}

.loginCard + .row {
  margin: 0 auto;
}

.googleBtn {
  padding: 18px 24px;
  border-radius: 8px;
  border: 1px solid #cdcdcd;
  background: #fff;
}

.googleBtn:hover {
  background: #087c4d !important;
}

.largeButton {
  border-radius: 10px;
  color: white !important;
  width: 420px;
  background: #087c4d !important;
}

.largeButton:hover {
  background-color: #234d79 !important;
  color: white !important;
  font-weight: 600;
  transition: 500ms;
}

.form-check {
  margin-left: 15px;
  font-size: 12px;

  font-weight: 600;
  color: #444343;
}

.secondary {
  color: #444343;
  font-size: 12px !important;

  font-weight: 600;
  text-align: end !important;
  margin-left: 20px;
  text-decoration: none !important;
}

.noAccount {
  font-size: 13px;
}

/* css for forgot password */
.forgotPassword {
  height: 50vmax;
  background-image: url("../images/backgroundImg.svg");
}

.forgotImg {
  height: 20vmax;
}

.routeText {
  font-weight: 600;
  font-size: 36px;
  margin: 2rem 28rem;
}

.forgotPassword h6 {
  font-weight: 500;
  font-size: 20px;
}

.forgotPassword .blueText {
  width: 20px;
}
