/* styles.css */

#templateBodySection {
  padding: 40px;
}

#templateTypeSelector {
  background-color: #f4f4f4;
  border-radius: 15px;
  padding: 10px 0;
}
#templateTypeSelector .d-flex {
  margin-bottom: 0 !important;
}

#templateTypeSelector li {
  list-style: none;
  padding: 5px;
}

#templateTypeSelector li:hover {
  color: white;
}

#templateTypeSelector {
  padding: 10px;
}

#selectTemplate {
  background-image: url("./images/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* this is used to style the accepted, rejected and under review tab */
#selectableTabs {
  border-radius: 12px;
  background-color: white;
}

.rounded-ends > div:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-ends > div:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
/* styles.css */
/* styles.css */
/* FiveColumnLayout.css */

.accepted:hover {
  border-radius: 10px;
  background: #039938;
  color: white;
  cursor: pointer;
}

.accepted.active {
  border-radius: 10px;
  background: #039938;
  color: white; /* Change the text color to white for better visibility */
}

.marUtilAuth_Changer {
  padding: 15px 12px;
}

.hover-div {
  display: none;
  background-color: green;
  color: white;
  padding: 0.5rem;
  /* Use rem for border-radius */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Rest of your styles */

.inner-div {
  background-color: white;
  padding: 10px;
}

.custom-margin-top {
  margin-bottom: 1.25rem; /* 20px in rem units */
}
/* Custom styles for the ResearchSection component */
.research-section {
  text-align: center;
  font-family: Roboto;
  font-size: 18px;

  letter-spacing: 0em;
  margin-bottom: 20px;
}

.research-heading {
  font-family: Raleway;
  font-size: 30px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 10px;
}

/* styles.css */

#templateBodySection .custom-card {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

#templateBodySection .custom-card-header {
  position: relative;
  border: none;
  border-bottom: 1px solid rgb(0, 0, 0, 0.4); /* Remove default border */
}

#templateBodySection .custom-card-footer {
  position: relative;
  border: none; /* Remove default border */
  border-top: 1px solid rgb(0, 0, 0, 0.4);
}
#templateBodySection .card {
  background-color: white;
  height: 28vmax;
}

#templateBodySection .card:hover {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.templateHeader .templateCardImage {
  height: 2rem;
}

.templateHeader {
  padding: 10px;
}

.templateHeader h4 {
  color: #343a40;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.templateBody {
  margin: 0 20px !important ;
  height: 13rem;
}

.templateContentHead {
  font-size: 18px;
  font-weight: 700;
  font-family: Roboto;
}
.templateContentBody {
  color: #2e2e2e;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.templateContentFooter {
  font-size: 12px !important;
}

.namethat {
  font-family: Raleway;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.type {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.custom-div {
  background-color: white;
  position: relative;

  /* Add any desired padding */
}
.custom-card-body {
  display: flex;
  align-items: flex-start;
}

.content-block {
  margin-left: -60px;
}

.custom-div::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: black;
  opacity: 0.2;
}
.custom-divbefore {
  background-color: white;
  position: relative;
  padding: 10px;
  /* Add any desired padding */
}

.custom-divbefore::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: black;
  opacity: 0.2;
}
/* Apply green color to the active tab of accepted, rejected and underreview tabs*/

/* Add this to your CSS file */
.custom-card-footer {
  position: relative; /* Required for absolute positioning */
}

.select-button-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: none; /* Hide the button by default */
}

.custom-card-footer:hover .select-button-container {
  display: block; /* Show the button when hovering over the card-footer */
}

.custom-card-header-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem; /* Add spacing in rem units */
}

.side-by-side {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Adjust the gap between image and text in rem units */
}

.type-below {
  margin-top: -0.325rem; /* Equivalent to -5.2px */
  margin-left: 3rem; /* Adjust the margin in rem units */
}
/* Your existing styles for .col, .see-all-link, etc. */

.heading-container {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.see-all-link {
  color: green;
  text-decoration: none;
}

.content-block {
  margin-right: 20px;
}
.options-div {
  border: 1px solid green;
}
.dashed {
  border: 2px dashed green; /* 2px width dashed border with a black color */
}

.custom-container {
  background-image: url("./images/hover.png");
  background-size: cover; /* Adjust this property as needed */
  background-repeat: no-repeat;
  background-position: center; /* Adjust this property as needed */
  width: 100%;
  height: 100%; /* Adjust this property as needed */
  /* Add other styles for the container */
}
.blackButton {
  background-color: black;
  color: white;
}

.cardBtn {
  text-decoration: none !important;
}

.footertext-success {
  display: flex;
  align-items: center;
  /* Set height to 100% */
}
.container {
  display: flex;
  align-items: center;
}
