/* @import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.greenBack {
  background-color: #087c4d;
  color: White;
}

.blueText {
  color: #2050c8;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}

p {
  font-family: "Roboto", sans-serif;
}

/* css for entire sidebar */
nav {
  width: 15rem;
  position: fixed;
}
.sidebar {
  position: sticky;
  width: 18rem !important;
}

.brand {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 700;
  color: white;
  margin-top: 10px !important;
  font-style: oblique;
  text-align: end !important;
}

.brandIcon {
  margin: 19px 0px 12px 20px;
  width: 15vmax;
}

.menuItem {
  text-decoration: none;
  color: #000;
  font-size: 18px;
  margin-left: 20px;
  margin-top: 10px;
  padding-bottom: 6px;
  font-weight: 500;
}

.myWidth {
  width: 80% !important;
}

.icon {
  margin-right: 10px;
  margin-left: 12px;
  font-size: 25px;
}

.active {
  color: white !important;
  border-radius: 10px;
  background: #153480;
  font-weight: 600;
}

.plusPoint {
  background-color: #0dc16b;
  color: white;
  border-radius: 10px;
  margin: 100px 20px 60px 30px !important;
  height: 220px;
  padding: 5px;
  text-align: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.plusPoint > h5 {
  font-size: 15px;
  padding-top: 12px;
}

.plusPoint > p {
  font-size: 12px;
  color: black;
  font-weight: 600;
  margin: 10px 20px 0 20px;
}

/* The below css is for the header */
#header {
  background-color: white;
  padding: 0px 5px;
}

.mainName {
  margin-top: 6px;
  font-size: 28px !important;

  font-weight: 600;
}

.headerCompany p,
.headerCompany b {
  color: #000;
  font-size: 14px;
  font-family: Montserrat;
  font-style: normal;
  line-height: normal;
}

.headerCompany p {
  margin-top: -5px;
}

.companyImage {
  border: solid grey 1px;
  margin-right: 25px;
  width: 58px;
  height: 55px;
  border-radius: 50%;
}

.notificationHead {
  width: 37px;
  height: 42px;
  margin-top: 10px;
  margin-left: -10px;
  margin-right: 10px;
}

/* This class is used to give background shadow */
.mediaBack {
  background-color: white;
  padding: 40px 0;
  margin-top: 15px;
  border-radius: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

/* Im using h6 for small texts */
/* h6 {
  font-size: 12px !important;
} */

.btn:hover {
  background-color: #07a919 !important;
}

h4 {
  font-size: 22px;
  text-transform: capitalize;
  line-height: 1.5;
  font-weight: 700;
}

/* code by harshini*/
.div1 {
  background-color: whitesmoke;
  border: 1px solid white;
  height: 200px;
  width: 500px;
  position: absolute;
  top: 30%;
  left: 20%;
}
.greenbutton {
  position: absolute;
  top: 68%;
  left: 20%;
}
.green {
  position: absolute;
  top: 40%;
  left: 50%;
  background-color: #0dc16b;
}
.greenbutton1 {
  position: absolute;
  top: 40%;
  left: 9%;
  background-color: #05b00b;
}
.our {
  color: black;
  border-radius: 10px;
  margin: 100px 20px 60px 30px !important;
  height: 220px;
  padding: 5px;
  text-align: center;
  box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px,
    rgb(0 0 0 / 30%) 0px 1px 3px -1px;
  position: absolute;
  bottom: 110px;
  width: 120px;
  left: 650px;
}
.template {
  position: absolute;
  bottom: 340px;
  width: 1000px;
  left: -60px;
}
.super-btn {
  border: none;
  background: green;
  color: white;
  padding: 0.75em 1.25em;
  border-radius: 0.5em;
}
.cardextra {
  overflow: hidden;
  text-overflow: ellipsis;
}
.desx {
  position: "absolute";
  top: "10%";
}
.template1 {
  position: absolute;
  bottom: 280px;
  width: 1000px;
  left: -60px;
}
