#metrics {
  background-color: #d2ffd6;
  padding: 10px;
}
.metricItems {
  background-color: white !important;
  padding: 10px;
  margin: 20px;
}
#metrics h2 {
  font-size: 32px;
}

#metrics span {
  font-weight: 600;
}

.metricCards {
  width: 30rem !important;
  border: solid 1px black;
}

.metricCards > h6 {
  font-weight: 600 !important;
}
.react-calendar {
  width: 34rem !important;
  padding: 20px 10px 40px 10px !important;
  border-radius: 20px;
  border: 1px solid black !important;
}
.react-calendar .react-calendar__navigation {
  margin-bottom: 30px;
}
.react-calendar button {
  font-size: 18px;
}

.hide {
  display: none !important;
}

.templateAnalytics {
  height: 40rem !important;
  width: 40rem !important;
}
